<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar
          active="addresses"
          :user="user.userName"
          :image="user.image"
        />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile p-3 address-card">
          <div class="header-profile">
            <h2>
              {{ $t("Add a new address") }}
            </h2>
          </div>
          <div class="from-all">
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-info" @click="geolocate">
                 {{$t(" Detect My Location")}}
                </button>
                <p>Selected Position: {{ marker.position }}</p>
              </div>
              <div class="col-md-6 text-left">
                <button class="btn-addaddress btn" @click="save">{{$t("save")}}</button>
              </div>
            </div>

            <GmapMap
              :center="center"
              :zoom="13"
              map-style-id="roadmap"
              style="width: 100%; height: 600px"
              ref="mapRef"
              @click="handleMapClick"
            >
              <GmapMarker
                :position="marker.position"
                :clickable="true"
                :draggable="true"
                @drag="handleMarkerDrag"
                @click="panToMarker"
              />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      marker: { position: { lat: 21.4925, lng: 39.17757 } },
      center: { lat: 21.4925, lng: 39.17757 },
    };
  },
  methods: {
    save() {
      sessionStorage.lat = this.marker.position.lat;
      sessionStorage.lng = this.marker.position.lng;

      this.$router.go(-1);
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    }, //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    }, //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(13);
    }, //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
  },
  created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.user = res.data;
    });
  },
  mounted() {
    this.geolocate();
  },
};
</script>
<style></style>
